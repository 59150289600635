import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUmbrellaBeach,
  faShoppingBasket,
  faWalking,
  faSubway,
  faFish,
  faLandmark,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faUmbrellaBeach,
  faShoppingBasket,
  faWalking,
  faSubway,
  faFish,
  faLandmark,
  faAngleDoubleUp
);

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;
